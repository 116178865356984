import { Component } from 'react';
import './App.css';
import checkedIcon from './images/round_checked.png';
import uncheckedIcon from './images/round_unchecked.png';
import cameraIcon from './images/camera.png';

class App extends Component {
  state = {
    c1: false,
    c2: false,
    c3: false,
    c4: false,
    content: '',
    pics: [],
    toastText: '',
    visible: false,

    debugMsg: "debugMsg",
  }

  componentDidMount() {
    const { aplus_queue } = window;
    aplus_queue.push({
      action: 'aplus.sendPV',
      arguments: [
        { 
          is_auto: false 
        },
        {
          param1: '111',
          param2: '222'
        }
      ]
    });
  }

  showToast = text => {
    this.setState({toastText: text});
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      this.setState({toastText: ''});  
    }, 1500);
  }

  showSuccessModal = () => {
    this.setState({visible: true})
  }
  
  hideSuccessModal = () => {
    this.setState({
      visible: false,
      c1: false,
      c2: false,
      c3: false,
      c4: false,
      content: '',
      pics: [],
    })
  }

  // disabled="disabled"
  /**
   * 此方法判断是否是在微信浏览器中
   */
  isWeixin = ()=> {
    const WxObj = window.navigator.userAgent.toLowerCase();
    console.log({WxObj})
    // this.setState({debugMsg: WxObj});
    if(WxObj.indexOf('micromessenger') > -1) {
      return true;
    } else {
      return false;
    }
  }

  // getFile = ({ accept = "image/png,image/jpg,image/jpeg" } = {}) => {
  getFile = ({ accept = "image/*" } = {}) => {
    let fileInput;
    if (!fileInput) {
      fileInput = document.createElement('input');
      console.log("fileInput:", fileInput);
      console.log("fileInput.disabled:", fileInput.disabled);
    }
    if(this.isWeixin()) {
      fileInput.disabled = false;
    }
    fileInput.class = "input";
    fileInput.type = 'file';
    fileInput.value = '';
    fileInput.accept = accept;

    document.body.appendChild(fileInput)

    return new Promise((resolve, reject) => {
      // fileInput.onchange = (event) => {
      //   this.setState({debugMsg: "fileInput.onchange" });
      //   const file = event.target.files[0];
      //   if (file) {
      //     resolve(file);
      //   } else {
      //     reject(Error('获取文件失败'));
      //   }
      // };

      fileInput.addEventListener("change", (event) => {
        this.setState({debugMsg: "fileInput.onchange" });
        const file = event.target.files[0];
        if (file) {
          resolve(file);
        } else {
          reject(Error('获取文件失败'));
        }
        document.body.removeChild(fileInput);
      })

      // fileInput.click();
      const timeout = setTimeout(() => {
        fileInput.click();
        clearTimeout(timeout);
      }, 100);
    });
  };

  selectPic = () => {
    if(this.state.pics.length > 4) {
      this.showToast('图片不能超过5张！')
      return;
    }
    this.getFile()
      .then(res => {
        // this.setState({debugMsg: res.toString()})
        this.setState({pics: [...this.state.pics, res]});
      })
      .catch(error => {
        // this.setState({debugMsg: error.toString()})
        console.log(error);
      })
  }

  submit = () => {
    const {
      state: {
        c1, c2, c3, c4, content, pics
      }
    } = this;
    if(!c1 && !c2 && !c3 && !c4) {
      this.showToast("请选择投诉该账号的原因")
      return;
    }
    if(!content) {
      this.showToast("请填写投诉说明")
      return;
    }
    if(pics.length === 0) {
      this.showToast("请上传截图")
      return;
    }

    // 上报点击事件
    const { aplus_queue } = window;
    aplus_queue.push({
      action: 'aplus.record',
      arguments: [
        'submit', 
        'CLK', 
        {
          param1: '111',
          param2: '222',
          param3: 333
        }
      ]
    });

    this.showSuccessModal();
  }

  render() {
    const {
      state: {
        c1, c2, c3, c4, content, pics, toastText, visible
      }
    } = this;
    if(visible) {
      return <div className="App-success-modal">
        <img className="App-success-modal-icon" src={checkedIcon} alt=""/>
        <span className="App-success-modal-tip">投诉已提交</span>
        <span className="App-success-modal-content">我们会尽快核实反馈，感谢您的支持</span>
        <div className="App-success-modal-button" onClick={this.hideSuccessModal}>关闭</div>
      </div>
    }
    return <div className="App">
      {toastText && <div className="App-toast">
          <span className="App-toast-text">{toastText}</span>
        </div>
      }
      <div className="App-header">
        <span className="App-header-dot">*</span>
        <span className="App-header-text">请选择投诉该账号的原因</span>
      </div>
      <div className="App-item1" onClick={() => {this.setState({c1: !c1, c2: false, c3: false, c4: false })}} >
        <img className="App-item1-checkicon" src={c1? checkedIcon : uncheckedIcon} alt="" />
        <span className="App-item1-checktext">消息发送频繁，对我造成骚扰</span>
      </div>
      <div className="App-item1" onClick={() => {this.setState({c2: !c2, c1: false, c3: false, c4: false })}} >
        <img className="App-item1-checkicon" src={c2? checkedIcon : uncheckedIcon} alt="" />
        <span className="App-item1-checktext">回复态度恶劣</span>
      </div>
      <div className="App-item1" onClick={() => {this.setState({c3: !c3, c1: false, c2: false, c4: false })}} >
        <img className="App-item1-checkicon" src={c3? checkedIcon : uncheckedIcon} alt="" />
        <span className="App-item1-checktext">沟通长时间无响应</span>
      </div>
      <div className="App-item1" onClick={() => {this.setState({c4: !c4, c1: false, c2: false, c3: false })}} >
        <img className="App-item1-checkicon" src={c4? checkedIcon : uncheckedIcon} alt="" />
        <span className="App-item1-checktext">不解决咨询问题</span>
      </div>
      <div className="App-header">
        <span className="App-header-dot">*</span>
        <span className="App-header-text">投诉说明</span>
      </div>
      <textarea 
        className="App-item2" 
        placeholder='请输入投诉说明' 
        value={content}
        onChange={e => {
          e.preventDefault();
          this.setState({content: e.target.value});
        }}
      />
      <div className="App-header">
        <span className="App-header-dot">*</span>
        <span className="App-header-text">上传聊天截图</span>
      </div>
      <div className="App-item3" >
        {
          pics.map((item, index) =>
            <img
              key={index}
              className="App-item3-pic"
              alt=""
              src={URL.createObjectURL(item)}
            />
          )
        }
        <div className="App-item3-view" onClick={this.selectPic}>
          <img className="App-item3-view-icon" src={cameraIcon} alt="" />
          <span className="App-item3-view-text">上传照片</span>
        </div>
      </div>
      {/* <p>{this.state.debugMsg}</p> */}
      <div className="App-button" onClick={this.submit} >发表</div>
    </div>
  }
}

export default App;
